import React from "react";
import { Routes, Route } from "react-router-dom";
import RouteContainer from "./pages/RouteContainer";
const ExceptionPage = React.lazy(() => import("./pages/ExceptionPage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
const DashboardPage = React.lazy(() => import("./pages/DashboardPage"));
const FormPage = React.lazy(() => import("./pages/FormPage"));
const RulePage = React.lazy(() => import("./pages/RulePage"));
const RuleFormPage = React.lazy(() => import("./pages/RuleFormPage"));
const RuleFormEditPage = React.lazy(() => import("./pages/RuleFormEditPage"));
const ExportExcel = React.lazy(() => import("./pages/ExportExcel"));
const ZoomPage = React.lazy(() => import("./pages/ZoomPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const FilePage = React.lazy(() => import("./pages/FilePage"));
const index = (props) => {
  return (
    <>
      <Routes>
        <Route element={<RouteContainer />}>
          <Route exact path="/" element={<DashboardPage />} />
          <Route exact path="/excel" element={<ExportExcel />} />
          <Route exact path="/rule" element={<RulePage />} />
          <Route exact path="/file" element={<FilePage />} />
          <Route exact path="/rule-form" element={<RuleFormPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route
            exact
            path="/rule-form/edit/:id"
            element={<RuleFormEditPage />}
          />
          <Route exact path="/form/:kun_id/:png_id" element={<FormPage />} />
        </Route>
        <Route exact path="/exception-handling" element={<ExceptionPage />} />
        <Route exact path="/zoom" element={<ZoomPage />} />
        <Route exact path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default index;
