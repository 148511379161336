import * as actionTypes from "../actions/types";

export const setLoading = (val) => {
  return {
    type: actionTypes.SET_LOADING,
    data: val,
  };
};

export const setError = (val) => {
  return {
    type: actionTypes.SET_ERROR,
    data: val,
  };
};

export const setPath = (val) => {
  return {
    type: actionTypes.SET_PATH,
    data: val,
  };
};

