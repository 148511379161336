import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  loading: false,
  error: "",
  path: "",
};
const setLoading = (state, action) => {
  return updateObject(state, {
    loading: action.data,
  });
};
const setError = (state, action) => {
  return updateObject(state, {
    error: action.data,
  });
};
const setPath = (state, action) => {
  return updateObject(state, {
    path: action.data,
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return setError(state, action);
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_PATH:
      return setPath(state, action);
    default:
      return state;
  }
};

export default reducer;
