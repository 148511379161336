import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  vaksin: {
    produkId: "",
    tglKunjungan: ""
  },
};
const setVaksin = (state, action) => {
  return updateObject(state, {
    vaksin: {
      ...state.vaksin,
      [action.data.key]: action.data.value,
    },
  });
};

const resetVaksin = (state) => {
  return updateObject(state, {
    vaksin: initialState.vaksin,
  });
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VAKSIN:
      return setVaksin(state, action);
    case actionTypes.RESET_VAKSIN:
      return resetVaksin(state, action);
    default:
      return state;
  }
};

export default reducer;
