import { setError, setLoading } from "../../redux/actions";

export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps,
  };
};

export const formatNumber = (num) => {
  return "IDR " + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const parseToString = (val) => {
  return Number.isInteger(val) ? val.toString() : val;
};

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const isImgBase64 = (val) => {
  let dataArr = val.split(",");
  let base64Content = dataArr[1];
  let base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  return base64regex.test(base64Content);
};

export const errorHandler = (dispatch, err) => {
  if (err.toString().includes("Network Error")) {
    dispatch(setError("connection"));
  } else if (err.toString().includes("401")) {
    dispatch(setError("not-authenticated"));
  } else if (err.response && err.response.status === 401) {
    dispatch(setError("not-authenticated"));
  } else {
    dispatch(setError("system"));
  }
};
