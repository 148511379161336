export const APP_LOADING = "APP_LOADING";
export const SET_AUTH = "SET_AUTH";
export const LOGOUT = "LOGOUT";
export const SET_PATH = "SET_PATH";

//MASTER
export const SET_PEKERJAAN = "SET_PEKERJAAN";
export const SET_AGAMA = "SET_AGAMA";
export const SET_PENDIDIKAN = "SET_PENDIDIKAN";
export const SET_STATUS_NIKAH = "SET_STATUS_NIKAH";
export const SET_JENIS_ID = "SET_JENIS_ID";
export const SET_KAMAR = "SET_KAMAR";
export const SET_PRODUK = "SET_PRODUK";
export const SET_LIST_DOCTOR = "SET_LIST_DOCTOR";
export const SET_LIST_POLI = "SET_LIST_POLI";
export const SET_DETAIL_DOCTOR = "SET_DETAIL_DOCTOR";
export const SET_TIPE_BERKAS = "SET_TIPE_BERKAS";
export const SET_PENANGGUNG = "SET_PENANGGUNG";


//AUTH
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_IDENTITAS = "SET_IDENTITAS";
export const RESET_IDENTITAS = "RESET_IDENTITAS";
export const SET_IDENTITAS_AUTO = "SET_IDENTITAS_AUTO";


//REGISTER
export const SET_VAKSIN = "SET_VAKSIN";
export const RESET_VAKSIN = "RESET_VAKSIN";

//FORM UBE
export const SET_FORM_UBE = "SET_FORM_UBE";
export const SET_RULE_UBE_GROUP = "SET_RULE_UBE_GROUP";
export const SET_RULE_UBE = "SET_RULE_UBE";
export const UPDATE_FORM_UBE = "UPDATE_FORM_UBE";
export const SET_RAWAT_INAP_PULANG = "SET_RAWAT_INAP_PULANG";
export const SET_DATA_UBE = "SET_DATA_UBE";
export const SET_UBE_FILTER = "SET_UBE_FILTER";
export const RESET_UBE_FILTER = "RESET_UBE_FILTER";
export const SET_LOCK_UBE = "SET_LOCK_UBE";
export const SET_USER_UBE = "SET_USER_UBE";
export const SET_FORM_UBE_DETAIL = "SET_FORM_UBE_DETAIL";
export const SET_LIST_FILES = "SET_LIST_FILES";
export const ADD_LIST_FILE = "ADD_LIST_FILE";
export const EDIT_FILE = "EDIT_FILE";
export const EDIT_RULE_UBE = "EDIT_RULE_UBE";


