// Palette

import { PRI_400, NTRL_100, PRI_100, PRI_500, PRI_600 } from "../colors";

const radius = "10px";

const style = {
  styleOverrides: {
    root: {
      borderRadius: radius,
      textTransform: "none",
      width: "100%",
      color: PRI_500,
      "&:hover": {
        backgroundColor: PRI_100,
      },
    },
    outlined: {
      color: PRI_500,
      borderRadius: radius,
      backgroundColor: "#FFF",
      borderColor: PRI_500,
      "&:hover": {
        backgroundColor: PRI_100,
        borderColor: PRI_600,
        color: PRI_600,
      },
    },
    contained: {
      backgroundColor: PRI_500,
      borderRadius: radius,
      color: NTRL_100,
      "&:hover": {
        backgroundColor: PRI_600,
      },
    },
    containedPrimary: {
      backgroundColor: PRI_500,
    },
  },
};
export default style;
