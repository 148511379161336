import axios from "../../helpers/axios/axios";
import { setLoading } from ".";
import { BASE_URL } from "../../helpers/common/config";
import { errorHandler } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import moment from "moment";

export const updateFormUbe = (val) => {
  return {
    type: actionTypes.UPDATE_FORM_UBE,
    data: val,
  };
};
export const resetFilterUbe = () => {
  return {
    type: actionTypes.RESET_UBE_FILTER,
  };
};
const setRawatInapPulang = (action) => {
  return {
    type: actionTypes.SET_RAWAT_INAP_PULANG,
    data: action.data,
  };
};
const setFormUbe = (action) => {
  return {
    type: actionTypes.SET_FORM_UBE,
    data: action.data,
  };
};
const setRuleUbeGroup = (action) => {
  return {
    type: actionTypes.SET_RULE_UBE_GROUP,
    data: action.data,
  };
};

const setRuleUbe = (action) => {
  return {
    type: actionTypes.SET_RULE_UBE,
    data: action.data,
  };
};

const setUserUbe = (action) => {
  return {
    type: actionTypes.SET_USER_UBE,
    data: action.data,
  };
};

export const setFormUbeDetail = (action) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_FORM_UBE_DETAIL,
      data: action,
    });
  };
};

export const getRawatInapPulang = (params) => {
  const url = BASE_URL + "/api/layanan/rawat-inap/pulang";
  return (dispatch, state) => {
    dispatch(setLoading(true));

    axios
      .get(url, {
        params: {
          no_mr: params.no_mr,
          page: params.page || 0,
          start_date: params.startDate,
          end_date: params.endDate,
          png_id: params.png_id,
          ps_nama: params.ps_nama,
          is_pulang: params.is_pulang,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setRawatInapPulang(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const findUbeUser = (body, action) => {
  const url = BASE_URL + "/api/ube/user/find";
  return (dispatch, state) => {
    dispatch(setLoading(true));

    axios
      .get(url, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        dispatch(setUserUbe(payload.data));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const checkUbeRule = (body, action) => {
  const url = BASE_URL + "/api/ube/rule";
  const { penanggung_id } = body;
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          penanggung_id: penanggung_id,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        if (payload.data) {
          if (payload.data.data.length > 0) {
            return action && action.failed();
          }
          return action && action.success();
        }

        throw Error;

        // if (payload.data) {
        //   return dispatch(setFormUbe(payload.data));
        // }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getUbeRule = (body) => {
  const url = BASE_URL + "/api/ube/rule";
  const { penanggung_id, kun_id } = body;
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          penanggung_id: penanggung_id,
          kun_id: kun_id,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setRuleUbe(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getUbeRuleGroup = () => {
  const url = BASE_URL + "/api/ube/rule-group";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setRuleUbeGroup(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getUbeData = ({ kunId, penanggungId, tipe_berkas_id }) => {
  const url = BASE_URL + "/api/ube/list";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        headers: {
          "x-auth-token": state().auth.token,
        },
        params: {
          kunId: kunId,
          penanggungId: penanggungId,
          tipe_berkas_id: tipe_berkas_id,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setFormUbe(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const addUbe = (body, action) => {
  const url = BASE_URL + "/api/ube/add";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        action && action.success(payload.data.data);
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch((err) => {
        action && action.failed();
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const addUbeRuleOne = (body, action) => {
  const url = BASE_URL + "/api/ube/rule/add";

  return (dispatch, state) => {
    dispatch(setLoading(true));
    return axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        if (payload.data.data) {
          action &&
            action.success &&
            action.success(payload.data.data.ube_rule_id);
        }
        // action && action.notif && action.notif("success");
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return (
            action &&
            action.failed &&
            action.failed({
              title: "Gagal Tambah",
              subtitle: err.response.data.message,
              open: true,
              type: "error",
            })
          );
        }
        // action && action.notif && action.notif("failed");
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        // action && action.button && action.button(false);
        dispatch(setLoading(false));
      });
  };
};

export const addUbeRule = (body, action) => {
  const url = BASE_URL + "/api/ube/rule/create";
  action && action.button && action.button(true);
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then(() => {
        action && action.notif && action.notif("success");
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch((err) => {
        action && action.notif && action.notif("failed");
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        action && action.button && action.button(false);
        dispatch(setLoading(false));
      });
  };
};

export const editUbeRuleChg = (action) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.EDIT_RULE_UBE,
      data: action,
    });
  };
};
export const editUbeRule = (body, action) => {
  const url = BASE_URL + "/api/ube/rule/edit";
  action && action.button && action.button(true);
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .put(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then(() => {
        action && action.notif && action.notif("success");
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch((err) => {
        action && action.notif && action.notif("failed");
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        action && action.button && action.button(false);
        dispatch(setLoading(false));
      });
  };
};

export const exportExcel = (body) => {
  const url = BASE_URL + "/api/ube/export";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          start_date: body.startDate,
          end_date: body.endDate,
          png_id: body.penanggung.png_id,
          with_rule: body.with_rule,
          no_mr: body.noMR,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
        responseType: "blob",
      })
      .then((payload) => {
        const url = window.URL.createObjectURL(new Blob([payload.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          moment().format("YYYY-MM-DD") + "_DATA_UBE.xlsx"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteUbeRule = (ube_rule_id, action) => {
  const url = BASE_URL + "/api/ube/rule/delete";

  return (dispatch, state) => {
    dispatch(setLoading(true));
    return axios
      .delete(url, {
        params: {
          ube_rule_id,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        return action && action.success && action.success();
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return (
            action &&
            action.failed &&
            action.failed({
              title: "Gagal Tambah",
              subtitle: err.response.data.message,
              open: true,
              type: "error",
            })
          );
        }
        // action && action.notif && action.notif("failed");
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        // action && action.button && action.button(false);
        dispatch(setLoading(false));
      });
  };
};

export const exportExcelData = (body) => {
  const url = BASE_URL + "/api/ube/export-data";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    console.log(body);
    axios
      .get(url, {
        params: {
          start_date: body.rawatPulangStart,
          end_date: body.rawatPulangEnd,
          no_mr: body.noMR,
          ps_nama: body.ps_nama,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
        responseType: "blob",
      })
      .then((payload) => {
        const url = window.URL.createObjectURL(new Blob([payload.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          moment().format("YYYY-MM-DD") + "_DATA_UBE.xlsx"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setFilterUbe = (body) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_UBE_FILTER,
      data: body,
    });
  };
};

const setLockUbe = (body) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_LOCK_UBE,
      data: body,
    });
  };
};

export const lockUbe = (body, action) => {
  const url = BASE_URL + "/api/ube/lock";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then(() => {
        dispatch(
          setLockUbe({
            si_ube_id: body.si_ube_id,
            is_locked: body.is_locked,
          })
        );
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch(() => {
        action && action.failed();
        // return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteUbe = (body, action) => {
  const url = BASE_URL + "/api/ube/remove";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .delete(url, {
        params: {
          si_ube_id: body.si_ube_id,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then(() => {
        dispatch(
          setLockUbe({
            si_ube_id: body.si_ube_id,
            is_locked: body.is_locked,
          })
        );
        action && action.success();

        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch(() => {
        action && action.failed();
        // return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

const setListFiles = (body) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.SET_LIST_FILES,
      data: body,
    });
  };
};

export const getListFiles = (body, action) => {
  const url = BASE_URL + "/api/ube/files";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        dispatch(setListFiles(payload.data.data));
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch(() => {
        action && action.failed();
        // return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

const addFile = (body) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.ADD_LIST_FILE,
      data: body,
    });
  };
};

export const addListFile = (body, action) => {
  const url = BASE_URL + "/api/ube/files/add";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        dispatch(addFile(payload.data.data));
        action && action("succsess");

        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch(() => {
        action && action("failed");

        // return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

const editFile = (body) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.EDIT_FILE,
      data: body,
    });
  };
};

export const editFileUbe = (body, action) => {
  const url = BASE_URL + "/api/ube/files/edit";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .put(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        dispatch(editFile(body));
        action && action("succsess");
        // if (payload.data) {
        //   return dispatch(setDataUbe(payload.data));
        // }
      })
      .catch(() => {
        action && action("failed");
        // return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
