import axios from "../../helpers/axios/axios";
import { BASE_URL } from "../../helpers/common/config";
import { errorHandler } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import { setLoading } from "./index";
const setPendidikan = (payload) => {
  return {
    type: actionTypes.SET_PENDIDIKAN,
    data: payload.data,
  };
};

const setPekerjaan = (payload) => {
  return {
    type: actionTypes.SET_PEKERJAAN,
    data: payload.data,
  };
};

const setAgama = (payload) => {
  return {
    type: actionTypes.SET_AGAMA,
    data: payload.data,
  };
};

const setStatusNikah = (payload) => {
  return {
    type: actionTypes.SET_STATUS_NIKAH,
    data: payload.data,
  };
};

const setJenisIdentitas = (payload) => {
  return {
    type: actionTypes.SET_JENIS_ID,
    data: payload.data,
  };
};

const setDataKamar = (payload) => {
  return {
    type: actionTypes.SET_KAMAR,
    data: payload.data,
  };
};

const setProduk = (payload) => {
  return {
    type: actionTypes.SET_PRODUK,
    data: payload.data,
  };
};

const setListDoctor = (payload) => {
  return {
    type: actionTypes.SET_LIST_DOCTOR,
    data: payload.data,
  };
};

const setListPoli = (payload) => {
  return {
    type: actionTypes.SET_LIST_POLI,
    data: payload.data,
  };
};

const setTipeBerkas = (payload) => {
  return {
    type: actionTypes.SET_TIPE_BERKAS,
    data: payload.data,
  };
};

const setPenanggung = (payload) => {
  return {
    type: actionTypes.SET_PENANGGUNG,
    data: payload.data,
  };
};

export const setDetailDoctor = (payload, action) => {
  action && action();
  return {
    type: actionTypes.SET_DETAIL_DOCTOR,
    data: payload,
  };
};

export const getPendidikan = (action) => {
  const url = BASE_URL + "/api/master/pendidikan";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setPendidikan(payload.data));
        }
        return dispatch(setPendidikan([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {});
  };
};

export const getAgama = (action) => {
  const url = BASE_URL + "/api/master/agama";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setAgama(payload.data));
        }
        return dispatch(setAgama([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPekerjaan = (action) => {
  const url = BASE_URL + "/api/master/pekerjaan";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setPekerjaan(payload.data));
        }
        return dispatch(setPekerjaan([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getJenisIdentitas = (action) => {
  const url = BASE_URL + "/api/master/jenis-identitas";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setJenisIdentitas(payload.data));
        }
        return dispatch(setJenisIdentitas([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getStatusNikah = (action) => {
  const url = BASE_URL + "/api/master/status-nikah";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setStatusNikah(payload.data));
        }
        return dispatch(setStatusNikah([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDataKamar = (action) => {
  const url = BASE_URL + "/api/simrs/data_kamar";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setDataKamar(payload.data));
        }
        return dispatch(setDataKamar([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDataProduk = (action) => {
  const url = BASE_URL + "/api/master/produk";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setProduk(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDokterList = (body, action) => {
  const url = BASE_URL + "/api/doctor/list";
  console.log(body);
  return (dispatch, state) => {
    dispatch(setLoading(true));

    axios
      .get(url, {
        params: {
          layanan_id: body.layananId,
          nama_dokter: body.namaDokter,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setListDoctor(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPoliList = (action) => {
  const url = BASE_URL + "/api/doctor/poli";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setListPoli(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getTipeBerkas = (action) => {
  const url = BASE_URL + "/api/master/tipe-berkas";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setTipeBerkas(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPenanggung = (action) => {
  const url = BASE_URL + "/api/master/penanggung";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setPenanggung(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
