import axios from "axios";
import { BASE_URL } from "../common/config";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  // headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Access-Control-Allow-Method': 'PUT, GET, POST, DELETE, OPTIONS',
  //     'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
  //     'Access-Control-Allow-Credentials': 'true'
  // }
});

// const getRefreshToken = () => {
//     axios
//         .get(
//             BASE_URL + "api/refresh",
//             {
//                 params: {
//                     token: localStorage.getItem("token"),
//                 },
//                 headers: {
//                     Accept: "application/json",
//                     "Content-Type": "application/json",
//                 },
//                 data: {}
//             }
//         )
//         .then((res) => {
//             return res.data.access_token;
//         })
//         .catch((err) => {
//             return;
//         });
// };

const getRefreshToken = async () => {
  try {
    const res = await axios.get(BASE_URL + "/api/auth/refresh-token-admin", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-refresh-token": localStorage.getItem("refresh_token")
          ? localStorage.getItem("refresh_token")
          : "",
      },
      data: null,
    });
    return res.data.data;
  } catch (err) {
    throw new Error(err);
    // return err;
  }
};

instance.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": localStorage.getItem("token"),
    };
    config.params = {
      ...config.params,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 410 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const access_token = await getRefreshToken();
        if (access_token) {
          localStorage.setItem("token", access_token);
        }
        // console.log(
        //   originalRequest.headers["x-auth-token"] ===
        //     localStorage.getItem("token")
        // );
        // console.log(
        //   originalRequest.headers.token === localStorage.getItem("token")
        // );
        return instance({
          headers: {
            ...originalRequest.headers,
            "x-auth-token": access_token,
          },
          ...originalRequest,
        });
      } catch (error) {
        localStorage.clear();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
