import React, { Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Router, BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
// import { createBrowserHistory } from "history";
import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PRI_600 } from "./theme/colors";
import { connect } from "react-redux";
// const browserHistory = createBrowserHistory();
const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1305,
    color: "#fff",
  },
}));
const App = (props) => {
  const classes = useStyles();
  const { stateLoading } = props;
  return (
    <ThemeProvider theme={theme}>
      <Backdrop className={classes.backdrop} open={stateLoading}>
        <center>
          <CircularProgress
            sx={{
              color: PRI_600 + "!important",
            }}
          />
        </center>
      </Backdrop>
      <BrowserRouter
      // basename="/web"
      >
        <Suspense
          fallback={
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                margin: "-25px 0 0 -25px",
              }}
            >
              <CircularProgress
                sx={{
                  color: PRI_600 + "!important",
                }}
              />
            </span>
          }
        >
          <Routes />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    stateLoading: state.app.loading,
  };
};

export default connect(mapStateToProps, null)(App);
