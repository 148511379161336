import * as colors from "./colors";

export default {
    fontFamily: ["Prompt"].join(","),
    h1: {
        fontWeight: "700",
        fontSize: "30px",
        letterSpacing: "-0.24px",
        lineHeight: "40px",
        fontFamily: "Prompt",
    },
    h2: {
        fontWeight: "700",
        fontSize: "26px",
        letterSpacing: "-0.24px",
        lineHeight: "36px",
        fontFamily: "Prompt",
    },
    h3: {
        fontFamily: "Prompt",
        fontStyle: "700",
        fontWeight: "700",
        fontSize: "22px",
        lineHeight: "32px",
    },
    h4: {
        fontWeight: "700",
        fontSize: "20px",
        letterSpacing: "-0.06px",
        lineHeight: "30px",
        fontFamily: "Prompt",
    },
    h5: {
        fontWeight: "700",
        fontSize: "20px",
        letterSpacing: "-0.05px",
        lineHeight: "30px",
    },
    h6: {
        fontWeight: "400",
        fontSize: "18px",
        letterSpacing: "-0.05px",
        lineHeight: "30px",
    },
    subtitle1: {
        fontFamily: "Prompt",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "28px",
    },
    subtitle2: {
        fontFamily: "Prompt",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "28px",
    },
    body1: {
        fontSize: "12px",
        letterSpacing: "-0.05px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        fontFamily: "Prompt",
    },
    body2: {
        fontFamily: "Prompt",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "10px",
        lineHeight: "24px",
    },
    button: {
        fontSize: "14px",
    },
    caption: {
        fontSize: "12px",
        letterSpacing: "0.3px",
        lineHeight: "16px",
    },
};
