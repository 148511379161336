import { BASE_URL, BASE_URL_IMG } from "../../helpers/common/config";
import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import moment from "moment";

const initialFilter = {
  rawatPulangStart: moment().startOf("month").format("YYYY-MM-DD"),
  rawatPulangEnd: moment().endOf("month").format("YYYY-MM-DD"),
  noMR: "",
  class: "",
  room: "",
  penanggung: {
    png_id: "",
    png_nama: "",
  },
  page: 1,
  ps_nama: "",
  is_pulang: false,
};

const initialState = {
  formUbe: [],
  formUbeDetail: localStorage.getItem("formUbeDetail")
    ? JSON.parse(localStorage.getItem("formUbeDetail"))
    : "",
  dataUbe: [],
  ruleUbeGroup: [],
  ruleUbe: [],
  filesUbe: [],
  rawatInapPulang: "",
  userUbe: localStorage.getItem("userUbe")
    ? JSON.parse(localStorage.getItem("userUbe"))
    : "",
  filter: localStorage.getItem("filterUbe")
    ? JSON.parse(localStorage.getItem("filterUbe"))
    : initialFilter,
};

const setFilter = (state, action) => {
  const { data } = action;
  if (data) {
    let filter = {
      ...state.filter,
      [data.key]: data.value,
    };
    localStorage.setItem("filterUbe", JSON.stringify(filter));
    return updateObject(state, {
      filter: filter,
    });
  }
  return;
};
const resetFilter = (state, action) => {
  localStorage.removeItem("filterUbe");
  return updateObject(state, {
    filter: initialFilter,
  });
};
const setFormUbe = (state, action) => {
  let arr = [];
  //   penanggung_id(pin):"-1"
  // kun_id(pin):"8964016"
  // si_ube_id(pin):"22"
  // filename(pin):"/ube/8964016_sep_1644473329872.png"
  // tipe_berkas_id(pin):3
  // nama_berkas(pin):"sep"
  action.data.map((item) => {
    return arr.push({
      penanggung_id: item.penanggung_id,
      input_type: item.input_type,
      placeholder: item.placeholder,
      si_ube_id: item.si_ube_id,
      tipe_berkas_id: item.tipe_berkas_id,
      nama_berkas: item.nama_berkas,
      filename:
        item.filename && item.input_type === "image"
          ? BASE_URL_IMG + item.filename
          : item.filename,
      is_locked: item.is_locked,
      rules_berkas_created_at: item.rules_berkas_created_at,
      rules_berkas_created_by: item.rules_berkas_created_by,
      file_updated_at: item.file_updated_at,
      file_updated_by: item.file_updated_by,
      file_created_at: item.file_created_at,
      file_created_by: item.file_created_by,
    });
  });
  return updateObject(state, {
    formUbe: arr,
  });
};
const updateFormUbe = (state, action) => {
  let update = state.formUbe.map((item) => {
    if (item.tipe_berkas_id === action.data.tipe_berkas_id) {
      return {
        ...item,
        filename: action.data.filename,
      };
    }
    return item;
  });
  return {
    ...state,
    formUbe: update,
  };
};
const setRawatInapPulang = (state, action) => {
  let i = 0;
  let update = [];
  if (action.data.data) {
    update = action.data.data.map((item) => {
      i++;
      return {
        no: i,
        ...item,
      };
    });
  }
  return updateObject(state, {
    rawatInapPulang: {
      data: update,
      total_row: action.data.total_row,
    },
  });
};
const setDataUbe = (state, action) => {
  return updateObject(state, {
    dataUbe: action.data,
  });
};

const setRuleUbeGroup = (state, action) => {
  let i = 0;
  let update = action.data.map((item) => {
    i++;
    return {
      no: i,
      ...item,
    };
  });
  return updateObject(state, {
    ruleUbeGroup: update,
  });
};
const setRuleUbe = (state, action) => {
  let arr = [];
  action.data.map((item) => {
    return arr.push({
      id: item.ube_rule_id,
      ube_rule_id: item.ube_rule_id,
      penanggung_id: item.penanggung_id,
      tipe_berkas_id: item.tipe_berkas_id,
      tipe_berkas: item.tipe_berkas.nama_berkas,
      value: item.tipe_berkas_id,
      label: item.tipe_berkas.nama_berkas,
      is_active: item.is_active,
      order: item.order,
    });
  });
  return updateObject(state, {
    ruleUbe: arr,
  });
};
const editRuleUbe = (state, action) => {
  let update = state.ruleUbe.map((item) => {
    if (item.ube_rule_id === action.data.ube_rule_id) {
      return {
        ...item,
        ube_rule_id: action.data.ube_rule_id,
        order: action.data.order,
      };
    }
    return item;
  });
  return {
    ...state,
    ruleUbe: update,
  };
};

const setLockUbe = (state, action) => {
  let update = state.formUbe.map((item) => {
    if (item.si_ube_id === action.data.si_ube_id) {
      return {
        ...item,
        is_locked: action.data.is_locked,
      };
    }
    return item;
  });
  return {
    ...state,
    formUbe: update,
  };
};

const setUserUbe = (state, action) => {
  localStorage.setItem("userUbe", JSON.stringify(action.data));
  return updateObject(state, {
    userUbe: action.data,
  });
};

const setFormUbeDetail = (state, action) => {
  localStorage.setItem("formUbeDetail", JSON.stringify(action.data));
  return updateObject(state, {
    formUbeDetail: action.data,
  });
};

const setFilesUbe = (state, action) => {
  let i = 0;
  let update = action.data.map((item) => {
    i++;
    return {
      no: i,
      ...item,
    };
  });
  return updateObject(state, {
    filesUbe: update,
  });
};

const addFile = (state, action) => {
  return updateObject(state, {
    filesUbe: [action.data, ...state.filesUbe],
  });
};

const editFile = (state, action) => {
  let update = state.filesUbe.map((item) => {
    if (item.tipe_berkas_id === action.data.tipe_berkas_id) {
      return {
        ...item,
        nama_berkas: action.data.nama_berkas,
        input_type: action.data.input_type,
      };
    }
    return item;
  });
  return {
    ...state,
    filesUbe: update,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FORM_UBE:
      return setFormUbe(state, action);
    case actionTypes.UPDATE_FORM_UBE:
      return updateFormUbe(state, action);
    case actionTypes.SET_RAWAT_INAP_PULANG:
      return setRawatInapPulang(state, action);
    case actionTypes.SET_DATA_UBE:
      return setDataUbe(state, action);
    case actionTypes.SET_RULE_UBE_GROUP:
      return setRuleUbeGroup(state, action);
    case actionTypes.SET_RULE_UBE:
      return setRuleUbe(state, action);
    case actionTypes.SET_UBE_FILTER:
      return setFilter(state, action);
    case actionTypes.RESET_UBE_FILTER:
      return resetFilter(state, action);
    case actionTypes.SET_LOCK_UBE:
      return setLockUbe(state, action);
    case actionTypes.SET_USER_UBE:
      return setUserUbe(state, action);
    case actionTypes.SET_FORM_UBE_DETAIL:
      return setFormUbeDetail(state, action);
    case actionTypes.SET_LIST_FILES:
      return setFilesUbe(state, action);
    case actionTypes.ADD_LIST_FILE:
      return addFile(state, action);
    case actionTypes.EDIT_FILE:
      return editFile(state, action);
    case actionTypes.EDIT_RULE_UBE:
      return editRuleUbe(state, action);
    default:
      return state;
  }
};

export default reducer;
