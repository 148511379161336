import auth from "./auth";
import master from "./master";
import app from "./app";
import register from "./register";
import formUbe from "./formUbe";
import { combineReducers } from "redux";

export default combineReducers({
  auth,
  master,
  app,
  register,
  formUbe,
});
