import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const RouteContainer = ({ error }) => {
  return <>{error ? <Navigate to={"/exception-handling"} /> : <Outlet />}</>;
};

const mapStateToProps = (state) => {
  return {
    error: state.app.error,
  };
};

export default connect(mapStateToProps, null)(RouteContainer);
