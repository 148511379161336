// PRIMARY
export const PRI_900 = "#041E66";
export const PRI_800 = "#072C7B";
export const PRI_700 = "#0B3E99";
export const PRI_600 = "#1054B7";
export const PRI_500 = "#166DD5";
export const PRI_400 = "#4B98E5";
export const PRI_300 = "#6FB7F2";
export const PRI_200 = "#A0D6FA";
export const PRI_100 = "#CFECFC";

// export const PRI_900 = "#0E3E79";
// export const PRI_800 = "#185892";
// export const PRI_700 = "#267BB6";
// export const PRI_600 = "#37A2D9";
// export const PRI_500 = "#4CCDFD";
// export const PRI_400 = "#78E2FD";
// export const PRI_300 = "#93F0FE";
// export const PRI_200 = "#B7FAFE";
// export const PRI_100 = "#DBFEFE";

// export const PRI_900 = "#7A1422";
// export const PRI_800 = "#932229";
// export const PRI_700 = "#B73835";
// export const PRI_600 = "#DB5B4E";
// export const PRI_500 = "#FF846B";
// export const PRI_400 = "#FFAB90";
// export const PRI_300 = "#FFC2A6";
// export const PRI_200 = "#FFDBC3";
// export const PRI_100 = "#FFEFE1";


// SECONDARY 1
export const SEC1_900 = "#131625";
export const SEC1_800 = "#1E222E";
export const SEC1_700 = "#2C2F38";
export const SEC1_600 = "#3D3E42";
export const SEC1_500 = "#87888D";
export const SEC1_400 = "#BFC0C5";
export const SEC1_300 = "#BFC0C5";
export const SEC1_200 = "#E7E8EC";
export const SEC1_100 = "#F2F3F5";

// SECONDARY 2
export const SEC2_900 = "#854462";
export const SEC2_800 = "#A76C82";
export const SEC2_700 = "#C79DA9";
export const SEC2_600 = "#E8D8DB";
export const SEC2_500 = "#F0E4E4";
export const SEC2_400 = "#F0E4E4";
export const SEC2_300 = "#F7EDEC";
export const SEC2_200 = "#FCF7F4";
export const SEC2_100 = "#FCFBF9";

// NEUTRAL
export const NTRL_700 = "#111111";
export const NTRL_600 = "#333333";
export const NTRL_500 = "#4A4A4A";
export const NTRL_400 = "#828282";
export const NTRL_300 = "#BDBDBD";
export const NTRL_200 = "#E0E0E0";
export const NTRL_100 = "#F2F2F2";
export const NTRL_000 = "#F9F9F9";
